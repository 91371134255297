<template>
  <b-container fluid>

    <form class="form" @submit.prevent="saveData2024">
      <card class="my-card ">
        <template v-slot:headerTitle>
          <h4><b>CREAR EMPRESA</b></h4>
        </template>

        <h4 class="px-4 py-4"><b>Información General</b></h4>
        <div class="wrapper-fields">

          <div class="wrapper-input" title="Nombre de la empresa">
            <label for="nombre">Nombre</label>
            <b-form-input id="nombre" name="nombre" required></b-form-input>
          </div>

          <!-- <div class="wrapper-input" title="Sector">
          <label for="sectorId">Sector</label>
          <HelexSelect id="sectorId" name="sectorId" :options="[{name: 'x', id: 0}]" optionLabel="name" optionValue="id"></HelexSelect>
        </div> -->

          <div class="wrapper-input" title="Número de Identificación Tributaria">
            <label for="nit">NIT</label>
            <b-form-input id="nit" name="nit" required></b-form-input>
          </div>

          <div class="wrapper-input" title="Dígito de verificación">
            <label for="dv">DV</label>
            <b-form-input id="dv" name="dv" required></b-form-input>
          </div>

          <div class="wrapper-input" title="País">
            <label for="paisId">País</label>
            <HelexSelect @input="onInputPais" id="paisId" name="paisId" :options="paises" optionLabel="descripcion"
              optionValue="id" required>
            </HelexSelect>
          </div>

          <div class="wrapper-input" title="Departamento">
            <label for="departamentoId">Departamento</label>
            <HelexSelect @input="onInputDepartamento" id="departamentoId" name="departamentoId" :options="departamentos"
              optionLabel="descripcion" optionValue="id" required></HelexSelect>
          </div>

          <div class="wrapper-input" title="Municipio / ciudad">
            <label for="ciudadId">Municipio</label>
            <HelexSelect id="ciudadId" name="ciudadId" :options="municipios" optionLabel="descripcion" optionValue="id"
              required>
            </HelexSelect>
          </div>

          <div class="wrapper-input" title="Dirección principal">
            <label for="direccionDelDomicilioPrincipal">Dirección principal</label>
            <b-form-input id="direccionDelDomicilioPrincipal" name="direccionDelDomicilioPrincipal"
              required></b-form-input>
          </div>

          <div class="wrapper-input" title="Tipo Vigilado">
            <label for="TipoVigiladoId">Tipo Vigilado</label>
            <HelexSelect id="TipoVigiladoId" name="TipoVigiladoId" :options="TipoVigilado" optionLabel="tipoVigilado"
              optionValue="id" required>
            </HelexSelect>
          </div>

        </div>

        <h4 class="mt-2 px-4 py-4"><b>Información de contacto</b></h4>
        <div class="wrapper-fields py-2px">

          <div class="wrapper-input" title="Nombre del contacto">
            <label for="nombreContacto">Primer nombre</label>
            <b-form-input id="nombreContacto" name="nombreContacto" required></b-form-input>
          </div>


          <div class="wrapper-input" title="Nombre del contacto">
            <label for="segundoNombreContacto">Segundo nombre</label>
            <b-form-input id="segundoNombreContacto" name="segundoNombreContacto"></b-form-input>
          </div>


          <div class="wrapper-input" title="Nombre del contacto">
            <label for="primerApellidoContacto">primer apellido</label>
            <b-form-input id="primerApellidoContacto" name="primerApellidoContacto" required></b-form-input>
          </div>


          <div class="wrapper-input" title="Nombre del contacto">
            <label for="segundoApellidoContacto">Segundo apellido</label>
            <b-form-input id="segundoApellidoContacto" name="segundoApellidoContacto"></b-form-input>
          </div>


          <div class="wrapper-input" title="Número del Contacto">
            <label for="telefonoContacto">Número del Contacto</label>
            <b-form-input id="telefonoContacto" name="telefonoContacto" required></b-form-input>
          </div>

          <div class="wrapper-input" title="Correo electrónico del Contacto">
            <label for="correo">Correo electrónico del Contacto</label>
            <b-form-input id="correo" name="correo" required type="email"></b-form-input>
          </div>

          <div class="wrapper-input" title="Contraseña para IFrames">
            <label for="contrasenaIFrame">Contraseña para IFrames</label>
            <b-form-input id="contrasenaIFrame" name="contrasenaIFrame" required></b-form-input>
          </div>

        </div>

        <div class="wrapper-button">

          <vs-button class="button"   :loading="waiting">Guardar</vs-button>
        </div>
      </card>
    </form>


  </b-container>
</template>
<script>
import Swal from 'sweetalert2';

export default {
  name: "Empresa_agregar",
  data() {
    return {
      paises: [],
      departamentos: [],
      municipios: [],
      TipoVigilado: [],
      waiting: false,
    };
  },
  async mounted() {
    this.getAllPaises();
    this.getAllTipoVigilado();
  },

  methods: {
    saveData2024(event) {
      const data = Object.fromEntries(new FormData(event.target).entries());

      this.waiting = true;
      this.$store.getters.fetchPost({
        path: `Empresa/Create2024`,
        data
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error("No se ha guardado la empresa")
        })
        .then(result => {
          console.log('result', result);
          this.savedSuccess();
        })
        .catch(() => {
          this.savedError();
        })
        .finally(() => {
          this.waiting = false;
        });

    },

    savedSuccess() {
      Swal.fire({
        title: 'Empresa creada',
        text: 'Se ha guardado la empresa',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      })
    },

    savedError() {
      Swal.fire({
        title: 'Error',
        text: 'No se ha guardado la empresa',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    },

    async getAllPaises() {
      const response = await this.$store.getters.fetchGet({ path: 'Pais/all' })
      this.paises = await response.json();
    },

    async getAllTipoVigilado() {
      const responseTipoVigilado = await this.$store.getters.fetchGet({ path: 'PesvModRegistroVigilado/all' })
      this.TipoVigilado = await responseTipoVigilado.json();
    },

    async getDepartamentosByPaisId(paisId) {
      const responseDepartamentos = await this.$store.getters.fetchGet({
        path: `DepartamentoPais/ListByPais/${paisId}`
      });
      this.departamentos = await responseDepartamentos.json();
    },

    async getMunicipiosByDepartamentoId(departamentoId) {
      const responseMunicipios = await this.$store.getters.fetchGet({
        path: `Municipio/ListByDepartamento/${departamentoId}`
      });
      this.municipios = await responseMunicipios.json();
    },

    onInputPais(paisId) {
      this.municipios = [];
      this.departamentos = [];

      const inputDepartamento = document.querySelector('[name="departamentoId"]');
      if (inputDepartamento) {
        inputDepartamento.value = null;
      }
      const inputCiudad = document.querySelector('[name="ciudadId"]');
      if (inputCiudad) {
        inputCiudad.value = null;
      }

      this.getDepartamentosByPaisId(paisId);
    },

    onInputDepartamento(departamentoId) {
      this.municipios = [];
      const inputCiudad = document.querySelector('[name="ciudadId"]');
      if (inputCiudad) {
        inputCiudad.value = null;
      }

      this.getMunicipiosByDepartamentoId(departamentoId);
    }
  },
};
</script>


<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0 48px 16px;
}


.wrapper-button {
  align-self: flex-end;
  padding: 0 48px 16px;
}

@media (max-width: 767px) {
  .wrapper-input {
  
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}
.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 5fr));
  gap: 1rem;
  padding: 0 20px 16px;
}
}
</style>